/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CoopPreliminarySearch } from '../models/coop-preliminary-search';
import { RerunCoopSearch } from '../models/rerun-coop-search';
import { SearchResultsReceivedDateRequest } from '../models/search-results-received-date-request';

@Injectable({
  providedIn: 'root',
})
export class CoopSearchControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateDonorSearchResultsReceivedDate
   */
  static readonly UpdateDonorSearchResultsReceivedDatePath = '/recipients/{recipientGuid}/coop-preliminary-searches/{id}/donor-results-received-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDonorSearchResultsReceivedDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorSearchResultsReceivedDate$Response(params: {
    id: number;
    context?: HttpContext
    body: SearchResultsReceivedDateRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoopSearchControllerService.UpdateDonorSearchResultsReceivedDatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDonorSearchResultsReceivedDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorSearchResultsReceivedDate(params: {
    id: number;
    context?: HttpContext
    body: SearchResultsReceivedDateRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateDonorSearchResultsReceivedDate$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateCordSearchResultsReceivedDate
   */
  static readonly UpdateCordSearchResultsReceivedDatePath = '/recipients/{recipientGuid}/coop-preliminary-searches/{id}/cord-results-received-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCordSearchResultsReceivedDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCordSearchResultsReceivedDate$Response(params: {
    id: number;
    context?: HttpContext
    body: SearchResultsReceivedDateRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoopSearchControllerService.UpdateCordSearchResultsReceivedDatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCordSearchResultsReceivedDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCordSearchResultsReceivedDate(params: {
    id: number;
    context?: HttpContext
    body: SearchResultsReceivedDateRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateCordSearchResultsReceivedDate$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation rerunCoopPreliminarySearches
   */
  static readonly RerunCoopPreliminarySearchesPath = '/recipients/{recipientGuid}/coop-preliminary-searches/rerun';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rerunCoopPreliminarySearches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunCoopPreliminarySearches$Response(params: {
    recipientGuid: string;
    context?: HttpContext
    body: RerunCoopSearch
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoopSearchControllerService.RerunCoopPreliminarySearchesPath, 'put');
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rerunCoopPreliminarySearches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunCoopPreliminarySearches(params: {
    recipientGuid: string;
    context?: HttpContext
    body: RerunCoopSearch
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.rerunCoopPreliminarySearches$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadCoopPreliminarySearches
   */
  static readonly LoadCoopPreliminarySearchesPath = '/recipients/{recipientGuid}/coop-preliminary-searches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadCoopPreliminarySearches()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopPreliminarySearches$Response(params: {
    recipientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CoopPreliminarySearch>>> {

    const rb = new RequestBuilder(this.rootUrl, CoopSearchControllerService.LoadCoopPreliminarySearchesPath, 'get');
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CoopPreliminarySearch>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadCoopPreliminarySearches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopPreliminarySearches(params: {
    recipientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CoopPreliminarySearch>> {

    return this.loadCoopPreliminarySearches$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CoopPreliminarySearch>>) => r.body as Array<CoopPreliminarySearch>)
    );
  }

  /**
   * Path part for operation saveCoopPreliminarySearch
   */
  static readonly SaveCoopPreliminarySearchPath = '/recipients/{recipientGuid}/coop-preliminary-searches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCoopPreliminarySearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCoopPreliminarySearch$Response(params: {
    recipientGuid: string;
    context?: HttpContext
    body: CoopPreliminarySearch
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoopSearchControllerService.SaveCoopPreliminarySearchPath, 'post');
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCoopPreliminarySearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCoopPreliminarySearch(params: {
    recipientGuid: string;
    context?: HttpContext
    body: CoopPreliminarySearch
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.saveCoopPreliminarySearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
