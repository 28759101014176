/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SummaryCounts } from '../models/summary-counts';

@Injectable({
  providedIn: 'root',
})
export class SummaryCountsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSummaryCounts
   */
  static readonly GetSummaryCountsPath = '/summary-counts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSummaryCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSummaryCounts$Response(params: {
    searchGuid: string;
    isGamidaEligible?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SummaryCounts>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryCountsControllerService.GetSummaryCountsPath, 'get');
    if (params) {
      rb.query('searchGuid', params.searchGuid, {});
      rb.query('isGamidaEligible', params.isGamidaEligible, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryCounts>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSummaryCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSummaryCounts(params: {
    searchGuid: string;
    isGamidaEligible?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SummaryCounts> {

    return this.getSummaryCounts$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SummaryCounts>) => r.body as SummaryCounts)
    );
  }

}
