/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ComputedMatchCriteria } from '../models/computed-match-criteria';
import { MatchCriteriaDto } from '../models/match-criteria-dto';
import { MatchResultDto } from '../models/match-result-dto';
import { PageMatchResultDto } from '../models/page-match-result-dto';
import { PotentialSource } from '../models/potential-source';

@Injectable({
  providedIn: 'root',
})
export class MatchResultControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMatchResults
   */
  static readonly GetMatchResultsPath = '/match-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMatchResults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMatchResults$Response(params: {
    context?: HttpContext
    body: MatchCriteriaDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageMatchResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, MatchResultControllerService.GetMatchResultsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageMatchResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMatchResults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMatchResults(params: {
    context?: HttpContext
    body: MatchCriteriaDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageMatchResultDto> {

    return this.getMatchResults$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageMatchResultDto>) => r.body as PageMatchResultDto)
    );
  }

  /**
   * Path part for operation getComputedMatchResult
   */
  static readonly GetComputedMatchResultPath = '/match-results/computed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComputedMatchResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getComputedMatchResult$Response(params: {
    context?: HttpContext
    body: ComputedMatchCriteria
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: MatchResultDto;
}>> {

    const rb = new RequestBuilder(this.rootUrl, MatchResultControllerService.GetComputedMatchResultPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: MatchResultDto;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComputedMatchResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getComputedMatchResult(params: {
    context?: HttpContext
    body: ComputedMatchCriteria
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: MatchResultDto;
}> {

    return this.getComputedMatchResult$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: MatchResultDto;
}>) => r.body as {
[key: string]: MatchResultDto;
})
    );
  }

  /**
   * Path part for operation getEpsPotentialSources
   */
  static readonly GetEpsPotentialSourcesPath = '/match-results/potential-sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEpsPotentialSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEpsPotentialSources$Response(params: {
    rcpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<PotentialSource>>> {

    const rb = new RequestBuilder(this.rootUrl, MatchResultControllerService.GetEpsPotentialSourcesPath, 'get');
    if (params) {
      rb.query('rcpGuid', params.rcpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PotentialSource>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEpsPotentialSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEpsPotentialSources(params: {
    rcpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<PotentialSource>> {

    return this.getEpsPotentialSources$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<PotentialSource>>) => r.body as Array<PotentialSource>)
    );
  }

}
