/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserRequestDto } from '../models/user-request-dto';

@Injectable({
  providedIn: 'root',
})
export class WmdaDonorRequestControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getWmdaDonorsForPatient
   */
  static readonly GetWmdaDonorsForPatientPath = '/wmda-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWmdaDonorsForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWmdaDonorsForPatient$Response(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, WmdaDonorRequestControllerService.GetWmdaDonorsForPatientPath, 'get');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWmdaDonorsForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWmdaDonorsForPatient(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<string>> {

    return this.getWmdaDonorsForPatient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation sendWmdaRequest
   */
  static readonly SendWmdaRequestPath = '/wmda-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendWmdaRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWmdaRequest$Response(params: {
    bpGuid: string;
    context?: HttpContext
    body: UserRequestDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WmdaDonorRequestControllerService.SendWmdaRequestPath, 'post');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendWmdaRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWmdaRequest(params: {
    bpGuid: string;
    context?: HttpContext
    body: UserRequestDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.sendWmdaRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
