/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchSummaryCounts } from '../models/search-summary-counts';
import { SearchSummaryReport } from '../models/search-summary-report';
import { SearchSummaryReportUpdateRequest } from '../models/search-summary-report-update-request';

@Injectable({
  providedIn: 'root',
})
export class SearchSummaryReportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getReport
   */
  static readonly GetReportPath = '/search/summary/report';

  /**
   * Get HLA search summary report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReport$Response(params: {
    recipientGuid: string;
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SearchSummaryReport>> {

    const rb = new RequestBuilder(this.rootUrl, SearchSummaryReportControllerService.GetReportPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('searchGuid', params.searchGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchSummaryReport>;
      })
    );
  }

  /**
   * Get HLA search summary report
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReport(params: {
    recipientGuid: string;
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SearchSummaryReport> {

    return this.getReport$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SearchSummaryReport>) => r.body as SearchSummaryReport)
    );
  }

  /**
   * Path part for operation update
   */
  static readonly UpdatePath = '/search/summary/report';

  /**
   * Update HLA search summary report (with an option to publish it)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: {
    publish?: boolean;
    context?: HttpContext
    body: SearchSummaryReportUpdateRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchSummaryReportControllerService.UpdatePath, 'put');
    if (params) {
      rb.query('publish', params.publish, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update HLA search summary report (with an option to publish it)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: {
    publish?: boolean;
    context?: HttpContext
    body: SearchSummaryReportUpdateRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.update$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation requestReport
   */
  static readonly RequestReportPath = '/search/summary/report';

  /**
   * Request HLA search summary report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestReport$Response(params: {
    recipientGuid: string;
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchSummaryReportControllerService.RequestReportPath, 'post');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('searchGuid', params.searchGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request HLA search summary report
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestReport(params: {
    recipientGuid: string;
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.requestReport$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getReportsForRecipients
   */
  static readonly GetReportsForRecipientsPath = '/search/summary/report/by-guids';

  /**
   * Get HLA search summary reports for several patients
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportsForRecipients()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportsForRecipients$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: SearchSummaryReport;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SearchSummaryReportControllerService.GetReportsForRecipientsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: SearchSummaryReport;
        }>;
      })
    );
  }

  /**
   * Get HLA search summary reports for several patients
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReportsForRecipients$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportsForRecipients(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: SearchSummaryReport;
}> {

    return this.getReportsForRecipients$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: SearchSummaryReport;
}>) => r.body as {
[key: string]: SearchSummaryReport;
})
    );
  }

  /**
   * Path part for operation getCounts
   */
  static readonly GetCountsPath = '/search/summary/report/counts';

  /**
   * Get HLA search summary report counts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCounts$Response(params: {
    recipientGuid: string;
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SearchSummaryCounts>> {

    const rb = new RequestBuilder(this.rootUrl, SearchSummaryReportControllerService.GetCountsPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('searchGuid', params.searchGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchSummaryCounts>;
      })
    );
  }

  /**
   * Get HLA search summary report counts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCounts(params: {
    recipientGuid: string;
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SearchSummaryCounts> {

    return this.getCounts$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SearchSummaryCounts>) => r.body as SearchSummaryCounts)
    );
  }

  /**
   * Path part for operation getAllReportsForRecipient
   */
  static readonly GetAllReportsForRecipientPath = '/search/summary/report/all';

  /**
   * Get all HLA search summary reports for a patient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllReportsForRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReportsForRecipient$Response(params: {
    recipientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<SearchSummaryReport>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchSummaryReportControllerService.GetAllReportsForRecipientPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSummaryReport>>;
      })
    );
  }

  /**
   * Get all HLA search summary reports for a patient
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllReportsForRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReportsForRecipient(params: {
    recipientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<SearchSummaryReport>> {

    return this.getAllReportsForRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<SearchSummaryReport>>) => r.body as Array<SearchSummaryReport>)
    );
  }

}
