/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BundleSearch } from '../models/bundle-search';
import { ReRunParameterizedSearchRequest } from '../models/re-run-parameterized-search-request';
import { Search } from '../models/search';
import { SearchMaintenanceRequest } from '../models/search-maintenance-request';
import { SearchRequestDto } from '../models/search-request-dto';
import { SourceTypeSearch } from '../models/source-type-search';

@Injectable({
  providedIn: 'root',
})
export class SearchControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateSearchMaintenance
   */
  static readonly UpdateSearchMaintenancePath = '/searches/parameterized/maintenance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSearchMaintenance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSearchMaintenance$Response(params: {
    context?: HttpContext
    body: SearchMaintenanceRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.UpdateSearchMaintenancePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSearchMaintenance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSearchMaintenance(params: {
    context?: HttpContext
    body: SearchMaintenanceRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateSearchMaintenance$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation retrieveSearches
   */
  static readonly RetrieveSearchesPath = '/searches/parameterized';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveSearches()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveSearches$Response(params: {
    status: Array<'RUNNING' | 'COMPLETED' | 'FAILED' | 'HALTED' | 'PENDING' | 'INQUEUE' | 'INPROGRESS' | 'DEFERRED'>;
    viewedInd?: boolean;
    recipientGuid?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BundleSearch>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.RetrieveSearchesPath, 'get');
    if (params) {
      rb.query('status', params.status, {});
      rb.query('viewedInd', params.viewedInd, {});
      rb.query('recipientGuid', params.recipientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BundleSearch>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveSearches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveSearches(params: {
    status: Array<'RUNNING' | 'COMPLETED' | 'FAILED' | 'HALTED' | 'PENDING' | 'INQUEUE' | 'INPROGRESS' | 'DEFERRED'>;
    viewedInd?: boolean;
    recipientGuid?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BundleSearch>> {

    return this.retrieveSearches$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BundleSearch>>) => r.body as Array<BundleSearch>)
    );
  }

  /**
   * Path part for operation runSearch
   */
  static readonly RunSearchPath = '/searches/parameterized';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSearch$Response(params: {
    context?: HttpContext
    body: SearchRequestDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.RunSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSearch(params: {
    context?: HttpContext
    body: SearchRequestDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.runSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation clearSearches
   */
  static readonly ClearSearchesPath = '/searches/parameterized/{searchGuid}/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearSearches()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearSearches$Response(params: {
    searchGuid: string;
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.ClearSearchesPath, 'post');
    if (params) {
      rb.path('searchGuid', params.searchGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clearSearches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearSearches(params: {
    searchGuid: string;
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.clearSearches$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLatestSearch
   */
  static readonly GetLatestSearchPath = '/searches/parameterized/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearch$Response(params: {
    recipientId: string;
    phenotypeNum?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BundleSearch>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.GetLatestSearchPath, 'get');
    if (params) {
      rb.query('recipientId', params.recipientId, {});
      rb.query('phenotypeNum', params.phenotypeNum, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BundleSearch>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearch(params: {
    recipientId: string;
    phenotypeNum?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BundleSearch>> {

    return this.getLatestSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BundleSearch>>) => r.body as Array<BundleSearch>)
    );
  }

  /**
   * Path part for operation reRunSearch
   */
  static readonly ReRunSearchPath = '/searches/parameterized/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reRunSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reRunSearch$Response(params: {
    context?: HttpContext
    body: ReRunParameterizedSearchRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.ReRunSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reRunSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reRunSearch(params: {
    context?: HttpContext
    body: ReRunParameterizedSearchRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.reRunSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation runSourceTypeSearch
   */
  static readonly RunSourceTypeSearchPath = '/searches/parameterized/latest/source-type-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runSourceTypeSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSourceTypeSearch$Response(params: {
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
    body: SearchRequestDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.RunSourceTypeSearchPath, 'post');
    if (params) {
      rb.query('sourceType', params.sourceType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runSourceTypeSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSourceTypeSearch(params: {
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
    body: SearchRequestDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.runSourceTypeSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation clearSearches1
   */
  static readonly ClearSearches1Path = '/searches/parameterized/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearSearches1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clearSearches1$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.ClearSearches1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clearSearches1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clearSearches1(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.clearSearches1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getBundleSearch
   */
  static readonly GetBundleSearchPath = '/searches/parameterized/{searchBundleGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBundleSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundleSearch$Response(params: {
    searchBundleGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<BundleSearch>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.GetBundleSearchPath, 'get');
    if (params) {
      rb.path('searchBundleGuid', params.searchBundleGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BundleSearch>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBundleSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundleSearch(params: {
    searchBundleGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<BundleSearch> {

    return this.getBundleSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<BundleSearch>) => r.body as BundleSearch)
    );
  }

  /**
   * Path part for operation checkForDummySearch
   */
  static readonly CheckForDummySearchPath = '/searches/parameterized/status/{searchBundleGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkForDummySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForDummySearch$Response(params: {
    searchBundleGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Search>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.CheckForDummySearchPath, 'get');
    if (params) {
      rb.path('searchBundleGuid', params.searchBundleGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Search>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkForDummySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForDummySearch(params: {
    searchBundleGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Search> {

    return this.checkForDummySearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Search>) => r.body as Search)
    );
  }

  /**
   * Path part for operation getAvSearchSources
   */
  static readonly GetAvSearchSourcesPath = '/searches/parameterized/sources/{searchGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvSearchSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvSearchSources$Response(params: {
    searchGuid: string;
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.GetAvSearchSourcesPath, 'get');
    if (params) {
      rb.path('searchGuid', params.searchGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvSearchSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvSearchSources(params: {
    searchGuid: string;
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<string>> {

    return this.getAvSearchSources$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getSourceTypeSearch
   */
  static readonly GetSourceTypeSearchPath = '/searches/parameterized/source-type-search/{searchGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSourceTypeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceTypeSearch$Response(params: {
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SourceTypeSearch>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.GetSourceTypeSearchPath, 'get');
    if (params) {
      rb.path('searchGuid', params.searchGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SourceTypeSearch>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSourceTypeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceTypeSearch(params: {
    searchGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SourceTypeSearch> {

    return this.getSourceTypeSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SourceTypeSearch>) => r.body as SourceTypeSearch)
    );
  }

  /**
   * Path part for operation getLatestSearchGuidFromBundleSearch
   */
  static readonly GetLatestSearchGuidFromBundleSearchPath = '/searches/parameterized/bundle-search/{bundleSearchGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestSearchGuidFromBundleSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearchGuidFromBundleSearch$Response(params: {
    bundleSearchGuid: string;
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SearchControllerService.GetLatestSearchGuidFromBundleSearchPath, 'get');
    if (params) {
      rb.path('bundleSearchGuid', params.bundleSearchGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestSearchGuidFromBundleSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearchGuidFromBundleSearch(params: {
    bundleSearchGuid: string;
    sourceType: 'DONOR' | 'CORD' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<string> {

    return this.getLatestSearchGuidFromBundleSearch$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
